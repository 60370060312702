/* ==========================================================================
   Imports/Includes
   ========================================================================== */
@import '../_includes/variables';
@import '../_includes/mixins';

#page-home {
	min-height:100vh;
	background-color: $CNAVY;
	.page-wrap {
		background: rgb(48,95,114);
		background: -moz-linear-gradient(0deg, rgba(48,95,114,1) 0%, rgba(30,65,96,1) 100%);
		background: -webkit-linear-gradient(0deg, rgba(48,95,114,1) 0%, rgba(30,65,96,1) 100%);
		background: linear-gradient(0deg, rgba(48,95,114,1) 0%, rgba(30,65,96,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#305f72",endColorstr="#1e4160",GradientType=1);
	}
}

.animation-container {
	position:relative;
	width:100%;
	height:100vh;
	border:1px solid $CBLUE;

	display:flex;
	justify-content: center;
	align-content:center;
	align-items:center;

	overflow:hidden;
}
#ANIMATION-SVG {
	width:1600px;
	height:auto;
}
/*#ANIMATION-SVG {
	position:absolute;
	width:1600px;
	height:1600px;
	left:50%;
	top:50%;
	margin-left:-800px;
	margin-top:-800px;
}*/
#CENTER-PIECE-SVG {
	height:90%;
}
#clipImage {
	opacity:0;
	transition:0.5s opacity;
	&.show {
		opacity:1;
		transition:0.5s opacity;
	}
}
#imageOverlay {
	opacity:0;
	transition:0.5s opacity;
	&.show {
		opacity:1;
		transition:0.5s opacity;
	}
}

.center-piece-container {
	width:100%;
	height:100%;
	border:0px solid $WHITE;
	position:absolute;
	display:flex;
	left:0;
	top:0;
	justify-content: center;
	align-content:center;
	align-items:center;
}
.interactive-layer {
	width:100%;
	height:100%;
	border:0px solid $WHITE;
	position:absolute;
	display:flex;
	left:0;
	top:0;
	justify-content: space-between;
	.left-cont, .right-cont, .center-cont {
		width:30%;
		display:flex;
		flex-direction:row;
		flex-wrap: wrap;
		h1,h2,p,ul,li {
			font-weight: $normal;
			color: $CSKIN;
			a {
				color: $CSKIN;
				text-decoration: none;
			}
		}
		header,footer {
			width:100%;
		}
	}
	.left-cont {
		padding:50px 0 50px 50px;
		align-content:space-between;
		header {
			h1 {
				color: $WHITE;
			}
		}
	}
	.right-cont {
		width:30%;
		padding:50px 50px 50px 0;
		align-content:stretch;
		justify-content: flex-end;
		h2,p,ul,li {
			text-align:right;
		}
		header {
			h2 {
				color: $CTANNED;
				a {
					text-decoration: none;
					color: $CTANNED;
				}
			}
		}
	}
	.center-cont {
		width:40%;
		/*justify-content: center;*/
		align-content:center;
		align-items:center;
		align-content:stretch;
		p {
			text-align:center;
			font-size:25px;
		}
		.center-box {
			position:relative;
			width:100%;
			height:65%;
			border:0px solid $CBLUE;
			display:flex;
			align-content:space-between;
			flex-wrap:wrap;
			p {
				width:100%;
				margin:0 0 0 0 !important;
				padding:0 0 0 0 !important;
			}
			a {
				color: inherit;
				text-decoration:none;
			}
		}
		.center_p {
			opacity:1;
			transition:0.5s opacity;
			&.hide {
				opacity:0;
				transition:0.5s opacity;
			}
		}
		.item-title, .item-link-p {
			opacity:0;
			transition:0.5s opacity;
			color: $CBLUE;
			font-size:25px;
			font-family: $_FANCYFONT;
			&.show {
				opacity:1;
				transition:0.5s opacity;
			}
		}
		.item-link-p {
			font-size:18px;
		}
	}
	@include breakWidth(800) {
		flex-wrap:wrap;
		.left-cont, .right-cont, .center-cont {
			width:100%;
			h1,h2,p,ul,li {
				font-size:13px;
			}
		}
		.left-cont {
			display:none;
			footer {
				display:none;
			}
		}
		.right-cont {
			display:none;
		}
		.center-cont {
			/*display:none;*/
			width:100%;
		}
	}
	@include breakWidth(480) {
		.center-cont {
			h1,h2,p,ul,li {
				font-size:18px;
			}
		}
	}
}
@include breakWidth(480) {
	#CENTER-PIECE-SVG {
		height:60%;
	}
}

.portfolio-container {
	header {
		h2 {

		}
	}
	.gallery-cont {
		ul {
			list-style:none;
			margin:0 0 0 0;
			padding:0 0 0 0;
			li {
				margin:0 0 0 0;
				padding:5px 0 5px 0;
				cursor:pointer;
				span.link-circle {
					vertical-align: middle;
					display: inline-block;
					border-radius:50% 50% 50% 50%;
					margin-right:20px;
					border:1px solid $CBLUE;
					width:0;
					height:0;
				}
				span.port-link, a {
					color: $CBLUE;
					text-decoration:none;
				}
				&:hover {
					span.link-circle {
						width:16px;
						height:16px;
						transition:0.25s all;
						border-color: $WHITE;
					}
					span.port-link, a {
						color: $WHITE;
					}
				}
			}
		}
		.item-cont {
			padding:5px 0 5px 0;
			figure {
				position:relative;
				margin:0 0 0 0;
				img {
					width:150px;
					height:120px;
					border:1px solid $CBLUE;
				}
				figcaption {
					/*position:absolute;
					bottom:0;
					left:0;*/
					width:100%;
					text-align:center;
					@include alpha-attribute('background-color',rgba($BLACK,0.2),$WHITE);
					color: $CBLUE;
					font-size:12px;
				}
			}
		}
	}
}
