$BLACK: #000000;
$WHITE: #FFFFFF;
$RED: #d40000;

$BORDERGREY: #999999;
$COPYGREY: #595959;

/* theme colours */
$THEMECOLOR: #568ea6;
$CNAVY: #1e4160;
$CBLUEDARK: #305f72;
$CBLUE: #568ea6;
$CSKIN: #f1d1b5;
$CTANNED: #f0b7a4;
$CPINK: #f18c8e;
$CDGREY: #333333;
$CLGREY: #999999;
$CPURPLE: #9037aa;
$CHPURPLE: #6b008c;
$CYELLOW: #f1bb41;
$CHYELLOW: #dc9d00;

$CHBLUE: #0047ca;
$CGREEN: #80b539;
$CBLACK: #333333;
$CORANGE: #ff9c00;

/* nice colours */
$ORANGE: #ffae00;
$ORANGELIGHT: #ffc445;
$RED: #d40000;
$PINK: #ff3f53;
$NAVY: #003259;
$BLUE: #0084ff;
$LIME: #b6dd00;
$GREEN: #619900;
$YELLOW: #f5c700;
$LIGHTGREY: #aeaeae;
$BLACK: #000000;
$WHITE: #FFFFFF;
$GREY: #707070;

/** buttons */
$BTNDEFAULT: #979797;
$BTNSUBMIT: #0078B3;
$BTNCANCEL: #ffae00;
$BTNRED: #d40000;
$BTNHOVER: #707070;
$BTNDISABLED: #939598;
$BTNHOVERGREY: #666666;
/* button border radius */
$bRADIUS: 6px;


@import url('https://fonts.googleapis.com/css2?family=Share+Tech&family=Share+Tech+Mono&display=swap');
/*@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,300i,400,400i,700,700i|Roboto:100,100i,300,300i,400,400i,500,500i,700,700i');
@import url('https://fonts.googleapis.com/css?family=Lato:300,300i,400,400i,700,700i,900');
*/
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;700&display=swap');
/*font-family: 'Share Tech', sans-serif;
font-family: 'Share Tech Mono', monospace;*/

$_STDFont: 'Share Tech Mono', monospace;
$_ALTFont: 'Montserrat', sans-serif;
$_FANCYFONT: 'Share Tech', sans-serif;

/* FONT WEIGHTS */
$extralight: 100;
$light: 200;
$normal: normal;
$medium: 500;
$bold: 600;
$extrabold: 700;
$black: 900;
