@mixin breakWidth($size){
    @media screen and (max-width: #{$size}px){ @content; }
}
@mixin breakHeight($size){
    @media screen and (max-height: #{$size}px){ @content; }
}
@mixin breakPrint() {
    @media print { @content; }
}
/* ----------- iPad Pro ----------- */
/* Portrait and Landscape */
@mixin breakiPadPro($orientation) {
	@media only screen 
		and (min-width: 1024px) 
		and (max-height: 1366px) 
		and (orientation: $orientation) 
		and (-webkit-min-device-pixel-ratio: 1.5) {
			@content;
	}
}


@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}

/*
    http://thesassway.com/intermediate/mixins-for-semi-transparent-colors
*/
@mixin alpha-background-color($color, $background) {
    $percent: alpha($color) * 100%;
    $opaque: opacify($color, 1);
    $solid-color: mix($opaque, $background, $percent);
    background-color: $solid-color;
    background-color: $color;
}
@mixin alpha-attribute($attribute, $color, $background) {
    $percent: alpha($color) * 100%;
    $opaque: opacify($color, 1);
    $solid-color: mix($opaque, $background, $percent);
    #{$attribute}: $solid-color;
    #{$attribute}: $color;
}

/*
    Modify a specific size and return as percent or px
*/
@function sizeMod($modValue, $perc, $initialValue) {
    $value: $modValue*$initialValue;
    @if $perc {
        $value: strip-unit($value);
        $value: percentage( $value/100 );
    }
    @return $value; 
}


/*
// Casts a string into a number
//
// @param {String | Number} $value - Value to be parsed
//
// @return {Number}
*/
@function strToNumber($value) {
    @if type-of($value) == 'number' {
        @return $value;
    } @else if type-of($value) != 'string' {
        $_: log('Value for `to-number` should be a number or a string.');
    }
  
    $result: 0;
    $digits: 0;
    $minus: str-slice($value, 1, 1) == '-';
    $numbers: ('0': 0, '1': 1, '2': 2, '3': 3, '4': 4, '5': 5, '6': 6, '7': 7, '8': 8, '9': 9);
  
    @for $i from if($minus, 2, 1) through str-length($value) {
        $character: str-slice($value, $i, $i);

        @if not (index(map-keys($numbers), $character) or $character == '.') {
            @return to-length(if($minus, -$result, $result), str-slice($value, $i))
        }

        @if $character == '.' {
            $digits: 1; 
        } @else if $digits == 0 {
            $result: $result * 10 + map-get($numbers, $character);  
        } @else {
            $digits: $digits * 10;
            $result: $result + map-get($numbers, $character) / $digits;
        }
    }
  
    @return if($minus, -$result, $result);;
}


/*
// Add `$unit` to `$value`
//
// @param {Number} $value - Value to add unit to
// @param {String} $unit - String representation of the unit
//
// @return {Number} - `$value` expressed in `$unit`
*/
@function to-length($value, $unit) {
    $units: ('px': 1px, 'cm': 1cm, 'mm': 1mm, '%': 1%, 'ch': 1ch, 'pc': 1pc, 'in': 1in, 'em': 1em, 'rem': 1rem, 'pt': 1pt, 'ex': 1ex, 'vw': 1vw, 'vh': 1vh, 'vmin': 1vmin, 'vmax': 1vmax);

    @if not index(map-keys($units), $unit) {
        $_: log('Invalid unit `#{$unit}`.');
    }

    @return $value * map-get($units, $unit);
}



/* Strip the px,em,% unit from number */
@function strip-unit($number) {
    @if type-of($number) == 'number' and not unitless($number) {
        @return $number / ($number * 0 + 1);
    }

    @return $number;
}


/*
  px to percentage
  for that peskey design elements thats floating in a specific point in space
  */
@function pxtopercent($target, $parent: 16) {
    @return percentage($target / $parent);
}

/*
	GRADIENT GENERATOR
	https://codepen.io/cathydutton/pen/dPPpWj
	examples:
				$orange: #ffa600;
				$blue: #82d2e5;
				$green:#c1d72e;

				$list1: $blue, $green, $orange; 
				$list2: $blue 0%,$blue 33%,$green 33%,$green 66%, $orange 66%, $orange 100%; 
				$list3: $blue 0%,$blue 10%,$green 25%,$green 39%,$orange 79%,$orange 100%; 
				$list4: $blue, $green, $orange, $blue, $green, $orange, $blue, $green, $orange;

				@include gradient(top, $list3);
				@include gradient(left, $list2);
*/
@mixin gradient($direction, $list) { 
	background: -webkit-linear-gradient($direction, $list);
	background: -moz-linear-gradient($direction, $list);
	background: -o-linear-gradient($direction, $list);
	background: linear-gradient($direction, $list);
}
